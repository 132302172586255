import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2';
import Vuelidate from 'vuelidate'
import VueClipboard from 'vue-clipboard2'
import OtpInput from "@bachdgvn/vue-otp-input";

import 'sweetalert2/dist/sweetalert2.min.css';
import 'animate.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import i18n from './i18n'

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard);
Vue.use(BootstrapVue);

const options = {
  confirmButtonColor: '#33AD4A',
  cancelButtonColor: '#ff7674',
};

Vue.use(VueSweetalert2, options);
Vue.use(Vuelidate);
Vue.use(IconsPlugin);
Vue.component("v-otp-input", OtpInput);
Vue.config.productionTip = false



new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
