<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      changeBg: false,
    };
  },
};
</script>

<style>
:root {
  height: 100% !important;
}
body {
  margin: 0;
  /* overflow: hidden; */
  height: 100%;
}
main {
  overflow: hidden !important;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  /* background: linear-gradient(180deg, #1b1e36, 91%, var(--primary)); */
}

.divider {
  display: block;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.divider > div:first-child {
  /* border-right: 1px solid rgba(0,0,0,0.2); */
  min-height: 100vh;
}

.trx-table {
  font-size: 13px;
  text-align: center;
  margin-top: 1rem;
  padding: 20px;
  /* padding-left: 0.5rem */
}

.trx-table th,
.trx-table td {
  font-size: 16px;
  border: 1px !important;
}
.trx-table td {
  border-bottom: none;
  /* padding: 1px 20px; */
  vertical-align: middle;
  height: 44px !important;
}

@media only screen and (min-width: 768px) {
  .divider {
    display: grid;
    grid-template-columns: 220px auto;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
}
</style>
