import Vue from 'vue'
import VueRouter from 'vue-router'
const Layout = () => import('@/views/Layout')
const Login = () => import('@/views/Login')
const Register = () => import('@/views/Register')
const ForgotPassword = () => import('@/views/ForgotPassword')
const ResetPassword = () => import('@/views/ResetPassword')
const Wallet = () => import('@/views/Wallet')
const Transaction = () => import('@/views/Transaction')
const RequestHistory = () => import('@/views/RequestHistory')
const Swap = () => import('@/views/Swap')
const Profile = () => import('@/views/Profile')
const Notification = () => import('@/views/Notification')
const Balance = () => import('@/views/Balance')
const ReuseBalance = () => import('@/views/ReuseBalance')
const CoinSetting = () => import('@/views/CoinSetting')
const DepositDetail = () => import('@/views/DepositDetail')
const CreateDeposit = () => import('@/views/CreateDeposit')
const Landing = () => import('@/views/Landing')
const Onboard = () => import('@/views/Onboard')
const TwoFa = () => import('@/views/TwoFa')
const Operators = () => import('@/views/Operators')
const Activate = () => import('@/views/Activate')
const WithdrawalApproval = () => import('@/views/WithdrawalApproval');
const TransactionGasFee = () => import('@/views/GasFee');


// import Layout from "../views/Layout.vue"
// import Login from "../views/Login.vue"
// import Register from "../views/Register.vue"
// import ForgotPassword from "../views/ForgotPassword.vue"
// import ResetPassword from "../views/ResetPassword.vue"
// import Wallet from "../views/Wallet.vue"
// import Transaction from "../views/Transaction.vue"
// import RequestHistory from "../views/RequestHistory.vue"
// import Swap from "../views/Swap.vue"
// import Profile from "../views/Profile.vue"
// import Notification from "../views/Notification.vue"
// import Balance from "../views/Balance.vue"
// import ReuseBalance from "../views/ReuseBalance.vue"
// import CoinSetting from "../views/CoinSetting.vue"
// import DepositDetail from "../views/DepositDetail.vue"
// import CreateDeposit from "../views/CreateDeposit.vue"
// import Landing from "../views/Landing.vue"
// import Onboard from "../views/Onboard.vue"
// import TwoFa from "../views/TwoFa.vue"
// import Operators from "../views/Operators.vue"
// import Activate from "../views/Activate.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{
      requiresAuth:false
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta:{
      requiresAuth:false
    },
  },
  {
    path: '/onboard',
    name: 'Onboard',
    component: Onboard,
    meta:{
      requiresAuth:false
    },
  },
  {
    path: '/2fa/:email',
    name: 'TwoFa',
    component:TwoFa,
    meta:{
      requiresAuth:false
    },
  },
  {
    path: '/operators/activate',
    name: 'Activate',
    component:Activate,
    meta:{
      requiresAuth:false
    },
  },
  {
    path: '/password/forgot',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta:{
      requiresAuth:false
    },
  },
  {
    path: '/password/reset',
    name: 'ResetPassword',
    component: ResetPassword,
    meta:{
      requiresAuth:false
    },
  },
  {
    path: '/deposit/:network/:platformId/:id',
    name: 'DepositDetail',
    component: DepositDetail,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/createDeposit/:merchantKey',
    name: 'CreateDeposit',
    component: CreateDeposit,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/dashboard',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/wallet',
        name: 'Wallet',
        component: Wallet,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: '/transaction',
        name: 'Transaction',
        component: Transaction,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: '/request/history',
        name: 'RequestHistory',
        component: RequestHistory,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: '/swap',
        name: 'Swap',
        component: Swap,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: '/operators',
        name: 'Operatos',
        component: Operators,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: '/withdrawalapproval',
        name: 'WithdrawalApproval',
        component: WithdrawalApproval,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: '/coinsetting',
        name: 'CoinSetting',
        component: CoinSetting,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: '/notification',
        name: 'Notification',
        component: Notification,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: '/wallet/:network/:symbol',
        name: 'Balance',
        component: Balance,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: '/wallet-r/:symbol',
        name: 'ReuseBalance',
        component: ReuseBalance,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: '/coinsetting/:id',
        name: 'CoinSetting',
        component: CoinSetting,
        meta:{
          requiresAuth:true
        },
      },
      {
        path: '/gasfee',
        name: 'TransactionGasFee',
        component: TransactionGasFee,
        meta:{
          requiresAuth:true
        },
      }
    ]
  },
  { path: '*', redirect: '/' } // catch all use case
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, _, next) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)

  // if(to.name == 'DepositDetail' || to.name == 'CreateDeposit'){
  //   return next();
  // }

  // let token = localStorage.getItem('coconut');
  // // let token = 'bla';
  // if (to.meta.requiresAuth && !token) {
  //   // console.log(to.fullPath);
  //   // this route requires auth, check if logged in
  //   // if not, redirect to login page.
    
  //   return next({name:'Login'})
  // }

  // if(!to.meta.requiresAuth && token){

  //   return next({name:'Wallet'});

  // }

  return next()
});

export default router
